<template>
  <div>
    <span class="text-h4 my-4">
      <b
        >Crédito Nro: {{ $filters.rfill(credit.sucursal) }}-{{
          $filters.rfill(credit.producto.id)
        }}-{{ credit.id }} - {{ credit.producto.name }}</b
      >
    </span>
    <ValidationObserver v-slot="{ invalid }" ref="form">
      <v-row>
        <v-col cols="5">
          <b>Monto a pagar: </b>
        </v-col>
        <v-col cols="7">
          {{ $filters.currency(totalToPay) }}
        </v-col>

        <v-col cols="5">
          <b>Banco: </b>
        </v-col>
        <v-col cols="7">
          <ng-select-options2
            v-model="form.banco"
            :rules="'required'"
            :items="bancos"
            label="Banco" :no-label="true"
          ></ng-select-options2>
        </v-col>

        <v-col cols="5">
          <b>Tipo de Persona: </b>
        </v-col>
        <v-col cols="7">
          <ng-select-options2
            v-model="form.tipo_persona"
            :rules="'required'"
            :items="typesPerson"
            label="Tipo de Persona" :no-label="true"
          ></ng-select-options2>
        </v-col>


        <v-col cols="5">
          <b>Nombre del pagador: </b>
        </v-col>
        <v-col cols="7">
          <ng-text2 v-model="form.nombre" :rules="'required|min:6'" label="Nombre del pagador" :no-label="true"></ng-text2>
        </v-col>

        <v-col cols="5">
          <b>Tipo de Documento: </b>
        </v-col>
        <v-col cols="7">
          <ng-select-options2
            v-model="form.tipo_documento"
            :rules="'required'"
            :items="types"
            label="Tipo de Documento" :no-label="true"
          ></ng-select-options2>
        </v-col>

        <v-col cols="5">
          <b>Número de identificación: </b>
        </v-col>
        <v-col cols="7">
          <ng-text2
            v-model="form.identificacion"
            :rules="'required|min:6'"
            label="Identificación" :no-label="true"
          ></ng-text2>
        </v-col>

        <v-col cols="5">
          <b>Número de celular: </b>
        </v-col>
        <v-col cols="7">
          <ng-phone2 v-model="form.celular" label="Celular" :no-label="true" :rules="'required|min:10'" maxlength="10"></ng-phone2>
        </v-col>

        <v-col cols="5">
          <b>Correo electrónico: </b>
        </v-col>
        <v-col cols="7">
          <ng-email2 v-model="form.email" :rules="'required|email'"
          label="Correo electrónico" :no-label="true"
          ></ng-email2>
        </v-col>

        <v-col cols="5">
          <b>Dirección: </b>
        </v-col>
        <v-col cols="7">
          <ng-text2 v-model="form.direccion" :rules="'required|min:6'" label="Dirección" :no-label="true"></ng-text2>
        </v-col>

        <v-col cols="12">
          <v-btn :disabled="invalid" @click="next" color="primary" block
            >Continuar</v-btn
          >
        </v-col>
      </v-row>
    </ValidationObserver>

        <template v-if="error">
    <v-dialog v-model="error" persistent  width="300">
      <v-card style="background-color: white !important">
        <v-card-text style="color: red">
          {{error}}
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="red" @click="error=false">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </template>

  </div>
</template>

<script>
export default {
  name: "PaymentClient",
  data: () => ({
    /*form: {
      banco: { id: 1022, name: "BANCO UNION COLOMBIANO" },
      nombre: "Geyffer Acosta",
      tipo_documento: null,
      identificacion: "94062962",
      celular: "3207105490",
      email: "geyffer@gmail.com",
      direccion: "Avenida 6",
    },*/
    form: {
      banco: null,
      nombre: "",
      tipo_documento: null,
      identificacion: "",
      celular: "",
      email: "",
      direccion: "",
    },
    types: [
      { id: "CedulaDeCiudadania", name: "Cédula de Ciudadanía" },
      { id: "CedulaDeExtranjeria", name: "Cédula de Extranjería" },
      { id: "Pasaporte", name: "Pasaporte" },
      { id: "NIT", name: "NIT" },
    ],
    typesPerson: [
      { id: "0", name: "Persona Natural" },
      { id: "1", name: "Persona Jurídica" }
    ],
    bancos: [],
    error: false
  }),
  props: ["credit", "type", "amount", "item"],
  watch: {
    item: {
      deep: true,
      handler(newVal) {
        console.log(newVal)
        this.form = newVal;
      }
    }
  },
  created() {
    //this.$http.post('')
    this.$http
      .getUrl("https://pse3.serprogreso.com/api/v1/list/banks")
      .then((response) => {
        //console.log(response);
        this.bancos = response.items;
      });
  },

  computed: {
    totalToPay() {
      if (this.type == "cuota") {
        return this.credit.cuota;
      }
      if (this.type == "total") {
        return this.credit.total;
      }
      if (
        this.type == "otro" &&
        this.amount > 0 &&
        this.amount <= this.credit.total
      ) {
        return this.amount;
      }
    },
  },
  mounted() {
    if(this.item){
      this.form = this.item;
      this.form['banco'] = null;
    }
  },
  methods: {
    next() {
      const value = this.form;
      value["total"] = this.totalToPay;
      value["credito"] = this.credit.id;
     /* this.$http
        .post("api/v1/payments/pse/create-transaction", value, this, true, true)
        .then((response) => {
          if (response.success) {*/
           // value["transaction_id"] = response.info.id;
            let url = location.href;
            if (location.href.indexOf("localhost") !== false) {
            } else {
              url = "https://movil.serprogreso.com";
            }
            value["url"] = url;
            this.$http
              .post(
                "https://pse3.serprogreso.com/api/v1/pse/create-transaction",
                value,
                this,
                true,
                true
              )
              .then((response) => {
                console.log(response);
                //this.bancos = response.items;
                if (response.success) {
                  //const data = { id: value["transaction_id"], data_response_pse: response.item };
                 // this.updateTransactionStatus(data).then(() => {
                    if (response.item) {
                      if (response.item.returnCode && response.item.pseURL) {
                        location.href = response.item.pseURL;
                      }else{
                       
                          if(response.item.returnCode == 'FAIL_EXCEEDEDLIMIT'){
                            //this.$store.commit('setError', 'El monto de la transacción excede los límites establecidos en PSE para la empresa, por favor comuníquese con la empresa');
                            this.error = 'El monto de la transacción excede los límites establecidos en PSE para la empresa, por favor comuníquese con la empresa';
                          }
                          if(  
                              response.item.returnCode == 'FAIL_ENTITYNOTEXISTSORDISABLED' 
                              || response.item.returnCode == 'FAIL_BANKNOTEXISTSORDISABLED'
                              || response.item.returnCode == 'FAIL_SERVICENOTEXISTSORNOTCONFIGURED'
                              || response.item.returnCode == 'FAIL_INVALIDAMOUNTORVATAMOUNT'
                              || response.item.returnCode == 'FAIL_INVALIDAMOUNT'
                              || response.item.returnCode == 'FAIL_INVALIDSOLICITDATE'
                              || response.item.returnCode == 'FAIL_CANNOTGETCURRENTCYCLE'
                              || response.item.returnCode == 'FAIL_ACCESSDENIED'
                              || response.item.returnCode == 'FAIL_TRANSACTIONNOTALLOWED'
                              || response.item.returnCode == 'FAIL_INVALIDPARAMETERS'
                              || response.item.returnCode == 'FAIL_GENERICERROR'
                              || response.item.returnCode == 'FAIL_NOTCONFIRMEDBYBANK'
                              || response.item.returnCode == 'FAIL_TIMEOUT'
                              || response.item.returnCode == 'FAILED'){
                            //this.$store.commit('setError', 'El monto de la transacción excede los límites establecidos en PSE para la empresa, por favor comuníquese con la empresa');
                            this.error = 'Transacción fallida, por favor intente más tarde o comuníquese con la empresa';
                          }
                        }
                    }
                  //});
                }
              });
       //   }
       // });

      /*  */
    },
    updateTransactionStatus(data) {
      return new Promise((resolve, reject) => {
        this.$http
        .post("payments/pse/update-transaction", data, this, true, true)
        .then((response) => {
          if (response.success) {
            resolve(true);
          }});
      })
    },
  },
};
</script>
<style scoped>
.v-list-item--active {
  color: #a10505 !important;
}
</style>