<template>
  <v-app>
    <v-content>
      <div
        style="
          margin-top: 60px;
          display: flex;
          flex-direction: column;
          align-items: center;
        "
      >
        <div style="display: flex; justify-content: center">
          <img src="/logo2.jpg" style="width: 300px" />
        </div>
  
        <div class="mt-10" style="margin-top: 30px; max-width: 520px">
          <v-card style="padding-top: 40px; margin: 10px" v-if="step == 2">
            <v-container
              class="py-0"
              style="width: 100%; min-width: 380px"
              v-if="credit"
            >
              <DetailCreditPayment :credit="credit" :item="item" :amount="formPayment.amount" :type="type"></DetailCreditPayment>
            </v-container>
          </v-card>
          <v-card style="padding-top: 40px; margin: 10px" v-if="step == 1">
            <v-container
              class="py-0"
              style="max-width: 380px; min-width: 380px"
              v-if="credit"
            >
              <span class="text-h4 my-4">
                <b
                  >Crédito Nro: {{ $filters.rfill(credit.sucursal) }}-{{
                    $filters.rfill(credit.producto.id)
                  }}-{{ credit.id }} - {{ credit.producto.name }}</b
                >
              </span>
        
                <v-list>
                  <v-list-item-group v-model="type" :multiple="false">
                    <v-list-item value="cuota" :disabled="credit.cuota <= 0">
                      <template v-slot:default="{ active }">
                        <v-list-item-content>
                          <v-list-item-title>{{
                            $filters.currency(credit.cuota)
                          }}</v-list-item-title>

                          <v-list-item-subtitle color="primary"
                            >Pago mínimo</v-list-item-subtitle
                          >
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-icon v-if="!active" color="primary">
                            mdi-checkbox-blank-circle-outline
                          </v-icon>

                          <v-icon v-else color="primary">
                            mdi-checkbox-blank-circle
                          </v-icon>
                        </v-list-item-action>
                      </template>
                    </v-list-item>

                    <v-list-item two-line value="total">
                      <template v-slot:default="{ active }">
                        <v-list-item-content color="primary">
                          <v-list-item-title color="primary">{{
                            $filters.currency(credit.total)
                          }}</v-list-item-title>
                          <v-list-item-subtitle color="primary"
                            >Deuda Total</v-list-item-subtitle
                          >
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-icon v-if="!active" color="primary">
                            mdi-checkbox-blank-circle-outline
                          </v-icon>

                          <v-icon v-else color="primary">
                            mdi-checkbox-blank-circle
                          </v-icon>
                        </v-list-item-action>
                      </template>
                    </v-list-item>

                    <v-list-item two-line value="otro">
                      <template v-slot:default="{ active }">
                        <v-list-item-content>
                          <v-list-item-title color="primary"
                            >Otro valor</v-list-item-title
                          >
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-icon v-if="!active" color="primary">
                            mdi-checkbox-blank-circle-outline
                          </v-icon>
                          <v-icon v-else color="primary">
                            mdi-checkbox-blank-circle
                          </v-icon>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                  </v-list-item-group>
                  <v-list-item v-if="type == 'otro'" class="py-2">
                     <ValidationObserver v-slot="{  }" ref="form">
                    <ng-money2
                      label='Valor'
                      :rules=" { required: true }"
                      v-model="formPayment.amount"
                    ></ng-money2>
              
                    </ValidationObserver>
                  </v-list-item>
                </v-list>
 
            </v-container>
            <v-list-item class="d-flex flex-column" v-if="credit">
              <div class="d-flex align-center">
                <img
                  src="https://app.serprogreso.com/logopse.png"
                  style="width: 64px; padding-right: 5px"
                />
                <span
                  >Su pago será procesado a través del Proveedor de Servicios
                  Electrónicos PSE</span
                >
              </div>
              <v-btn color="primary" :disabled="!isValueCorrect" @click="step = 2">Pagar con PSE</v-btn>
            </v-list-item>
            <ng-form :form="form" v-if="!credit">
              <v-container
                class="py-0"
                style="max-width: 380px; min-width: 380px"
              >
                <div
                  style="display: flex; justify-content: center"
                  class="mt-4"
                >
                  <v-btn
                    color="primary"
                    text
                    class="text-h5"
                    style="cursor: pointer"
                    
                  >
                    <v-icon class="mr-2">mdi-cash-multiple</v-icon>
                    Pagos en línea y PSE</v-btn
                  >
                </div>

                <v-row no-gutters>
                  <ng-text2
                    v-model="form.solicitud"
                    label="Número de Credito"
                    :rules = "'required'"
                    prepend-inner-icon="mdi-card-account-details"        
                    :dense="false"
                    :outlined="true"
             
                    @keyup.enter="next()"
                  />
                  <v-col cols="12">
                    <span
                      >Escribir solo los números del ejemplo resaltados en rojo.
                      Ej. 002-001-<b style="color: red">323</b></span
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                    "
                  >
                    <v-btn
                      elevation="1"
                      color="primary"
                      @click="next()"
                      class="mr-1"
                      :disabled="!form.solicitud"
                    >
                      Continuar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </ng-form>
          </v-card>
        </div>

        <div style="display: flex; justify-content: center">
          <router-link to="/" class="text-h5" style="cursor: pointer"
            >Inicio</router-link
          >
        </div>
      </div>
    </v-content>
  </v-app>
</template>

<script>
import { NgForm, handler } from "@/components/gui/NgForm";
import DetailCreditPayment from "./DetailCreditPayment.vue";

export default {
  components: { DetailCreditPayment },
  name: "PaymentClient",
  data: () => ({
    form: {},
    formPayment: { amount: 0 },
    step: 1,
    item: null,
    credit: null,
    type: "cuota",
    amount: 0,
  }),
  created() {
    const configFormMpn = {
      solicitud: {
        name: "solicitud",

      },
    };
    const form = new NgForm(configFormMpn, this);
    this.form = new Proxy(form, handler);
    this.form.init();

    

  },
  //http://localhost:8080/payments/finalize/029e4f34-3127-4da3-8a8a-1993f66d7256
  computed: {
    isValueCorrect() {
      if (!this.credit) {
        return false;
      }
      if (this.type == "cuota" && this.credit.cuota > 0) {
        return true;
      }
      if (this.type == "total") {
        return true;
      }
      if (
        this.type == "otro" &&
        this.formPayment.amount > 0 &&
        this.formPayment.amount <= this.credit.total
      ) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    let tr = this.$store.getters.transaction;
    if(tr){
      this.step = 2;
      this.infoCredit(tr.data_transaction.credito)
      this.formPayment.amount = tr.data_transaction.total;
      this.type = "otro";
      this.item = tr.data_transaction;

    }
    //console.log(this.$route.params)
    if(this.$route.params.id){
      this.infoCredit(this.$route.params.id)
    }

  },
  methods: {
    next() {
      this.infoCredit(this.form.solicitud)
    },
    infoCredit(nro){
      this.$http
        .post(
          "api/payments/solicitud/info/" + nro,
          {},
          this,
          true,
          true
        )
        .then((res) => {
          console.log(res);
          if (res.success) {
            //console.log(res.data)
            this.credit = res.info;
            
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
};
</script>
<style scoped>
.v-list-item--active {
  color: #a10505 !important;
}
</style>